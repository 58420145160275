import React from "react"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import { graphql, Link } from "gatsby"
// import ProductSwiper from "@components/productSwiper/product-recommended"
// import PlaceholderImage from "@images/placeholder-image.jpg"
import marked from "marked"
// import HeadingBG from "@images/pexels-curtis-adams-3773579.jpg"
import PageHeading from "@components/pageHeading"
import Gallery from "react-grid-gallery"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import RedQuote from "../components/modal/RedButtonQuote"

class GalleryTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      images: this.props.images,
    }
  }

  render() {
    const gallery = this.props.data.sanityAllGalleryWithAlt

    let images = gallery.image.map((img) => ({
      src: img.asset.url,
      thumbnail: img.asset.url,

      orientation: 1,
      caption: `${img.caption ? img.caption : "Gallery"}`,
      alt: `${img.alt ? img.alt : "Gallery"}`,
    }))
    const pList = gallery.productList
    // console.log("pList", pList)

    return (
      <Layout location={this.props.location}>
        <SeoComponent
          title={gallery.metaTitle}
          description={gallery.metaDescription}
        />
        <PageHeading
          bgImage={
            gallery?.heroBanner?.asset?.gatsbyImageData?.images?.fallback?.src
          }
        >
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-10">
                <h1 className="display-4 text-white">{gallery.title}</h1>
              </div>
            </div>
          </div>
        </PageHeading>

        <div className="py-10">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                {gallery.description ? (
                  <div
                    className="dynamic-page-content-wrapper"
                    dangerouslySetInnerHTML={{
                      __html: marked(gallery.description),
                    }}
                  />
                ) : null}
                {gallery.body ? (
                  <>
                    {pList.length !== 0 ? (
                      <h2 className="text-center mt-5">Select products</h2>
                    ) : null}
                    <div className="row">
                      {pList?.map((prod) => (
                        <div className="product-item col-md-4 my-5">
                          <Link to={"/" + prod?.slug?.current}>
                            <div className="product-image mb-2">
                              <div
                                className="image-bg"
                                style={{
                                  backgroundImage: `url(${prod?.productBanner?.asset?.url})`,
                                  backgroundSize: `cover`,
                                }}
                              ></div>
                            </div>
                            <span className="d-block product-name">
                              {prod.name}
                            </span>
                            {prod.price ? (
                              <span className="d-block text-muted font-italic lead product-price">
                                starting at ${prod.price}
                              </span>
                            ) : null}
                          </Link>
                        </div>
                      ))}
                    </div>
                    <div className="col-md-12 text-center mt-3 mb-5">
                      <h5 className="text-center mt-3 mb-3">
                        Contact us to discuss a custom wood project or explore
                        our pre-configured items available online in our shop.
                      </h5>
                      <a
                        class="btn btn-primary quote-btn mr-3 mb-0"
                        href="tel:719-985-3336"
                      >
                        CALL US
                      </a>
                      <RedQuote />
                    </div>
                  </>
                ) : null}
                {gallery.body ? (
                  <div
                    className="dynamic-page-content-wrapper"
                    dangerouslySetInnerHTML={{ __html: marked(gallery.body) }}
                  />
                ) : null}

                {gallery.body && gallery.image.length !== 0 ? (
                  <div style={{ marginTop: `6rem` }} />
                ) : null}
                {gallery.image.length !== 0 ? (
                  <div className="images-wrapper">
                    {/*<h3 className="text-center"></h3>*/}
                    <LazyLoadComponent>
                      <Gallery images={images} enableImageSelection={false} />
                    </LazyLoadComponent>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default GalleryTemplate

export const galleries = graphql`
  query GalleryQuery($id: String!) {
    sanityAllGalleryWithAlt(id: { eq: $id }) {
      title
      id
      body
      description
      image {
        asset {
          url
        }
      }
      heroBanner {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
        }
      }
      productList {
        id
        name
        productBanner {
          asset {
            url
          }
        }
        price
        slug {
          current
        }
      }
      form
      metaTitle
      metaKeywords
      metaDescription
    }
  }
`
